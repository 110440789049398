var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('div',{staticClass:"row mt-0 mb-4"},[_c('div',{staticClass:"col-12 col-md-12 m-0 p-0",staticStyle:{"margin-top":"-5px !important"}},[_c('DivisorColor',{attrs:{"ptl":"3px","ptr":"3px","pbl":"3px","pbr":"3px"}})],1)]),_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"outlined":"","color":"black","to":_vm.currentRoles.includes('SCO$SEGUIMIENTOCNS') ? "/proyectos/cns/seguimiento/cartera/reg" : "/proyectos/cns/externo/registro-informacion"}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Registro Seguimiento CNS ")]),_c('br'),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-md"},[_vm._v(" "+_vm._s(_vm.codigoProyecto)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","dark":""},on:{"click":_vm.modalNuevo}},[_vm._v(" Registrar Seguimiento ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.seguimientos,"search":_vm.filtro,"loading":_vm.tableSeguimientosLoading,"no-data-text":"No se encontraron registros","loading-text":"Cargando...","header-props":{
                  sortByText: 'Ordenar por'
              },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              'items-per-page-text': 'Registros por página',
              pageText: '{0}-{1} de {2}'
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Año, periodo","maxlength":"200"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}},[_c('v-icon',{attrs:{"slot":"append","color":"blue-grey darken-2"},slot:"append"},[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{staticClass:"d-none",attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text d-none",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.periodo))]),_c('td',[_vm._v(_vm._s(item.nombreBimestre))]),_c('td',[_vm._v(_vm._s(item.numeroBoleta))]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                        ? 'blue-grey lighten-4'
                        : item.estadosId === 2
                        ? 'deep-orange lighten-4'
                        : item.estadosId === 3
                        ? 'teal lighten-4'
                        : item.estadosId === 4
                        ? 'light-blue lighten-4'
                        : item.estadosId === 6
                        ? 'cyan lighten-4'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-4'
                        : 'pink lighten-4',"text-color":item.estadosId === 1
                        ? 'blue-grey lighten-1'
                        : item.estadosId === 2
                        ? 'deep-orange lighten-1'
                        : item.estadosId === 3
                        ? 'teal lighten-1'
                        : item.estadosId === 4
                        ? 'light-blue lighten-1'
                        : item.estadosId === 6
                        ? 'cyan darken-1'
                        : item.estadosId === 7
                        ? 'deep-orange lighten-1'
                        : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[((item.estadosId === 1 || item.estadosId === 3 || item.estadosId === 4) && item.procedencia == "SICOOPERA")?_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.mostrarDialogActualizar(item.id, item.periodosId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(_vm._s(item.estadosId==1 ? "Actualizar información" : item.estadosId === 3 || item.estadosId===4 ? "Visualizar" : "")+" ")],1):_vm._e(),_c('v-btn',{staticClass:"\n                      ma-2\n                      btn-bg-light  \n                      blue-grey--text\n                      lighten-2--text\n                      font-weight-medium\n                      text-capitalize\n                    ",attrs:{"target":"_blank","small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.obtenerDatosItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" Visualizar ")],1)],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"900","transition":"scroll-y-transition","persistent":"","scrollable":""},model:{value:(_vm.dialogRegistro),callback:function ($$v) {_vm.dialogRegistro=$$v},expression:"dialogRegistro"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogTitle)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
                _vm.resetForm();}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"d-none"}),_c('v-card-text',{staticClass:"pl-8 pr-8 pt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.periodos.length > 0)?_c('v-alert',{attrs:{"outlined":"","type":"info","prominent":"","border":"left"}},[_vm._v(" Se mostrarán únicamente los periodos y bimestres para los cuales se haya registrado previamente informe de avance físico y financiero. ")]):_vm._e()],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-4 pb-0",attrs:{"cols":"12","md":"6","sm":"12","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"dense":"","filled":"","items":_vm.registrosIaff,"color":"blue-grey lighten-2","return-object":"","label":"Seleccione año y bimestre","item-text":function (item) { return ((item.periodo) + " - " + (item.nombreBimestre)); },"item-value":"id","rules":[_vm.selectRequired('periodo')],"menu-props":"auto"},model:{value:(_vm.anioPeriodoSeleccionado),callback:function ($$v) {_vm.anioPeriodoSeleccionado=$$v},expression:"anioPeriodoSeleccionado"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","label":"Número de boleta","rules":[
                            _vm.required('Número de boleta'),
                            /*minLength('Número de boleta', 5),*/
                            _vm.maxLength('Número de boleta', 15)
                        ],"maxlength":"15"},model:{value:(_vm.datosItem.numeroBoleta),callback:function ($$v) {_vm.$set(_vm.datosItem, "numeroBoleta", $$v)},expression:"datosItem.numeroBoleta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-menu',{ref:"menuFechaRegistroIaff",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Fecha de registro IAFF","hint":"DD/MM/AAAA","persistent-hint":"","append-icon":"mdi-calendar","readonly":"","rules":[
                                    _vm.required('Fecha de registro en la dirección interna') ],"maxlength":"10"},model:{value:(_vm.computedFechaRegistroIaff),callback:function ($$v) {_vm.computedFechaRegistroIaff=$$v},expression:"computedFechaRegistroIaff"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaRegistroIaff),callback:function ($$v) {_vm.menuFechaRegistroIaff=$$v},expression:"menuFechaRegistroIaff"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es"},on:{"input":function($event){_vm.menuFechaRegistroIaff = false}},model:{value:(_vm.dateFechaRegistroIaff),callback:function ($$v) {_vm.dateFechaRegistroIaff=$$v},expression:"dateFechaRegistroIaff"}})],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-menu',{ref:"menuFechaRegistroIaffDireccionInterna",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"dense":"","filled":"","color":"blue-grey lighten-2","label":"Fecha de registro en la dirección interna","hint":"DD/MM/AAAA","persistent-hint":"","append-icon":"mdi-calendar","readonly":"","rules":[
                                    _vm.required('Fecha de registro en la dirección interna') ],"maxlength":"10"},model:{value:(_vm.computedFechaRegistroIaffDireccionInterna),callback:function ($$v) {_vm.computedFechaRegistroIaffDireccionInterna=$$v},expression:"computedFechaRegistroIaffDireccionInterna"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaRegistroIaffDireccionInterna),callback:function ($$v) {_vm.menuFechaRegistroIaffDireccionInterna=$$v},expression:"menuFechaRegistroIaffDireccionInterna"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es"},on:{"input":function($event){_vm.menuFechaRegistroIaffDireccionInterna = false}},model:{value:(_vm.dateFechaRegistroIaffDireccionInterna),callback:function ($$v) {_vm.dateFechaRegistroIaffDireccionInterna=$$v},expression:"dateFechaRegistroIaffDireccionInterna"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.itemsSiNo,"loading":false,"dense":"","filled":"","label":"Cumplimiento de ingreso de informe en tiempo establecido","item-text":"text","item-value":"id","menu-props":"auto","rules":[
                            _vm.selectRequired('Cumplimiento de ingreso de informe en tiempo establecido')
                        ]},model:{value:(_vm.datosItem.cumplimientoInformeTiempo),callback:function ($$v) {_vm.$set(_vm.datosItem, "cumplimientoInformeTiempo", $$v)},expression:"datosItem.cumplimientoInformeTiempo"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.itemsSiNo,"loading":false,"dense":"","filled":"","label":"Envío de documentación física requerida","item-text":"text","item-value":"id","menu-props":"auto","rules":[
                            _vm.selectRequired('Envío de documentación física requerida')
                        ]},model:{value:(_vm.datosItem.envioDocumentacionFisica),callback:function ($$v) {_vm.$set(_vm.datosItem, "envioDocumentacionFisica", $$v)},expression:"datosItem.envioDocumentacionFisica"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.itemsSiNo,"loading":false,"dense":"","filled":"","label":"Envío de información en formato digital","item-text":"text","item-value":"id","menu-props":"auto","rules":[
                            _vm.selectRequired('Envío de información en formato digital')
                        ]},model:{value:(_vm.datosItem.envioDocumentacionDigital),callback:function ($$v) {_vm.$set(_vm.datosItem, "envioDocumentacionDigital", $$v)},expression:"datosItem.envioDocumentacionDigital"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.itemsSiNo,"loading":false,"dense":"","filled":"","label":"Ficha de cumplimiento con firmas requeridas","item-text":"text","item-value":"id","menu-props":"auto","rules":[
                            _vm.selectRequired('Ficha de cumplimiento con firmas requeridas')
                        ]},model:{value:(_vm.datosItem.fichaCumplimientoFirmas),callback:function ($$v) {_vm.$set(_vm.datosItem, "fichaCumplimientoFirmas", $$v)},expression:"datosItem.fichaCumplimientoFirmas"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.itemsSiNo,"loading":false,"dense":"","filled":"","label":"Ficha de cumplimiento con sellos correspondientes","item-text":"text","item-value":"id","menu-props":"auto","rules":[
                            _vm.selectRequired('Ficha de cumplimiento con sellos correspondientes')
                        ]},model:{value:(_vm.datosItem.fichaCumplimientoSellos),callback:function ($$v) {_vm.$set(_vm.datosItem, "fichaCumplimientoSellos", $$v)},expression:"datosItem.fichaCumplimientoSellos"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"md":"12","sm":"12"}},[_c('v-spacer'),(_vm.accion === 1)?_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm || !_vm.anioPeriodoSeleccionado,"loading":_vm.btnRegistroLoading}},[_vm._v(" Guardar ")]):_vm._e(),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5 mr-2",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
                        _vm.resetForm();}}},[_vm._v(" "+_vm._s(_vm.accion === 1 ? "Cancelar" : "Cerrar")+" ")])],1)],1)],1)],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1)],1),_c('SnackAlert',{ref:"snackalert"}),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText,"transition":"scroll-y-transition"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }