<template lang="">
    <div>
        <v-card :elevation="1" class="v-sheet theme--light br-0">
            <div class="row mt-0 mb-4">
              <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
                 <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
              </div>
            </div>
            <v-card-title>
              <v-row class="col-md-12">
                <v-col cols="12">
                  
      
                  <v-btn
                    class="float-right"
                    outlined
                    color="black"
                    :to="currentRoles.includes('SCO$SEGUIMIENTOCNS') ? `/proyectos/cns/seguimiento/cartera/reg` : `/proyectos/cns/externo/registro-informacion`"
                  >
                  <v-icon>mdi-arrow-left</v-icon>
                    Regresar
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <span class="card-label font-weight-bolder text-dark">
                    Registro Seguimiento CNS </span
                  ><br />
                  <span class="text-muted mt-3 font-weight-bold font-size-md">
                    {{ codigoProyecto }}
                  </span>
                  
      
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-btn
                    color="light-blue-502"
                    dark
                    class="white--text mb-2 float-right"
                    @click="modalNuevo"
                  >
                    Registrar Seguimiento
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="col-md-12 m-0 p-0">
                <v-divider></v-divider>
              </v-row>
            </v-card-title>
            <v-card-text class="pl-0 pr-0">
              <v-data-table
                class="elevation-0"
                :headers="headers"
                :items="seguimientos"
                :search="filtro"
                :loading="tableSeguimientosLoading"
                no-data-text="No se encontraron registros"
                loading-text="Cargando..."
                :header-props="{
                      sortByText: 'Ordenar por'
                  }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  'items-per-page-text': 'Registros por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:top>
                  <!-- v-container, v-col and v-row are just for decoration purposes. -->
                  <v-container fluid>
                    <v-row>
                      <v-col cols="6" md="6" sm="6" xs="6">
                        <v-text-field
                          autocomplete="off"
                          class="required"
                          dense
                          filled
                          color="blue-grey lighten-2"
                          v-model="filtro"
                          label="Año, periodo"
                          maxlength="200"
                        >
                        <v-icon slot="append" color="blue-grey darken-2">mdi-magnify</v-icon>
                        </v-text-field>
                      </v-col>
      
                      <!-- <v-col cols="6" md="6" sm="6" xs="6">
                        <v-select
                          v-model="estadoProyecto"
                          :items="estadosProyecto"
                          :loading="false"
                          dense
                          filled
                          label="Estado"
                          :no-data-text="'No existen estados registrados'"
                          menu-props="auto"
                          return-object
                        ></v-select>
                      </v-col> -->
      
                      <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                        <v-btn
                          class="ma-0 white--text d-none"
                          medium
                          color="light-blue darken-2"
                        >
                          <v-icon left>mdi-magnify</v-icon> Buscar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
      
                <template v-slot:item="{ item }">
                  <tr>
                  
                    <td>{{ item.periodo }}</td>
                    <td>{{ item.nombreBimestre }}</td>
                    <td>{{ item.numeroBoleta }}</td>
                    <td>
                      <v-chip
                        class="ma-2 font-weight-medium"
                        label
                        :color="
                          item.estadosId === 1
                            ? 'blue-grey lighten-4'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-4'
                            : item.estadosId === 3
                            ? 'teal lighten-4'
                            : item.estadosId === 4
                            ? 'light-blue lighten-4'
                            : item.estadosId === 6
                            ? 'cyan lighten-4'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-4'
                            : 'pink lighten-4'
                        "
                        :text-color="
                          item.estadosId === 1
                            ? 'blue-grey lighten-1'
                            : item.estadosId === 2
                            ? 'deep-orange lighten-1'
                            : item.estadosId === 3
                            ? 'teal lighten-1'
                            : item.estadosId === 4
                            ? 'light-blue lighten-1'
                            : item.estadosId === 6
                            ? 'cyan darken-1'
                            : item.estadosId === 7
                            ? 'deep-orange lighten-1'
                            : 'pink darken-1'
                        "
                        small
                      >
                        {{ item.estado }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="(item.estadosId === 1 || item.estadosId === 3 || item.estadosId === 4) && item.procedencia == `SICOOPERA`"
                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                        small
                        depressed
                        color="blue-grey lighten-5"
                        @click="mostrarDialogActualizar(item.id, item.periodosId)"
                      >
                        <v-icon left>mdi-pencil</v-icon>{{ item.estadosId==1 ? `Actualizar información` : item.estadosId === 3 || item.estadosId===4 ? `Visualizar` : ``}} 
                      </v-btn>
      
                      <v-btn 
                        class="
                          ma-2
                          btn-bg-light  
                          blue-grey--text
                          lighten-2--text
                          font-weight-medium
                          text-capitalize
                        "
                        @click="obtenerDatosItem(item.id)"
                        target="_blank"
                        small
                        depressed
                        color="blue-grey lighten-5"
                      >
                        <v-icon left>mdi-eye</v-icon> Visualizar
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
            <!-- <template v-slot:html>
                  {{ code4.html }}
                </template>
                <template v-slot:js>
                  {{ code4.js }}
                </template> -->
          </v-card>


          <!--begin:: modal registro-->
        <v-dialog
            v-model="dialogRegistro"
            max-width="900"
            transition="scroll-y-transition"
            persistent
            scrollable
        >   
        <v-card tile>
            <v-card-title>
                {{dialogTitle}}

                <v-spacer></v-spacer>
                <v-btn
                icon
                :disabled="btnRegistroLoading"
                @click="
                    dialogRegistro = false;
                    resetForm();
                "
                class="float-right"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="d-none"></v-divider>

            <v-card-text class="pl-8 pr-8 pt-5">
                
                <v-row>
                <v-col cols="12">
                    <v-alert
                    v-if="periodos.length > 0"
                    outlined
                    type="info"
                    prominent
                    border="left"
                    >
                    Se mostrarán únicamente los periodos y bimestres para los cuales se haya registrado previamente informe de avance físico y financiero.
                    </v-alert>
                </v-col>
                </v-row>
                
                <v-form
                ref="form"
                v-on:submit.prevent="registrarItem"
                v-model="validForm"
                >
                <v-row>


                <v-col cols="12" md="6" sm="12" xs="12" class="pt-4 pb-0">
                    <v-select
                        dense
                        filled
                        v-model="anioPeriodoSeleccionado"
                        :items="registrosIaff"
                        class="required"
                        color="blue-grey lighten-2"
                        return-object
                        label="Seleccione año y bimestre"
                        :item-text="item => `${item.periodo} - ${item.nombreBimestre}`"
                        item-value="id"
                        :rules="[selectRequired('periodo')]"
                        menu-props="auto"
                    >
                    </v-select>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                
                    
                    <v-text-field
                            autocomplete="off"
                            class="required"
                            dense
                            filled
                            v-model="datosItem.numeroBoleta"
                            label="Número de boleta"
                            :rules="[
                                required('Número de boleta'),
                                /*minLength('Número de boleta', 5),*/
                                maxLength('Número de boleta', 15)
                            ]"
                            maxlength="15"
                            
                        ></v-text-field>
                
                </v-col>

                <!-- <v-col cols="12" md="6" sm="12" xs="12" class="pt-4 pb-0">
                    <v-select
                        dense
                        filled
                        v-model="datosItem.bimestreId"
                        :items="bimestres"
                        class="required"
                        color="blue-grey lighten-2"
                        label="Bimestre"
                        item-text="bimsetre"
                        item-value="id"
                        :rules="[selectRequired('bimestre')]"
                        menu-props="auto"
                    >
                    </v-select>
                    </v-col> -->

                </v-row>

            
                <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                    
                        <v-menu
                            ref="menuFechaRegistroIaff"
                            v-model="menuFechaRegistroIaff"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    dense
                                    filled
                                    color="blue-grey lighten-2"
                                    v-model="computedFechaRegistroIaff"
                                    label="Fecha de registro IAFF"
                                    hint="DD/MM/AAAA"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                    :rules="[
                                        required('Fecha de registro en la dirección interna'),
                                    ]"
                                    v-mask="'##/##/####'"
                                    maxlength="10"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                
                                v-model="dateFechaRegistroIaff"
                                no-title
                                @input="menuFechaRegistroIaff = false"
                                locale="es"
                            ></v-date-picker>
                        </v-menu>
                        
                    
                    </v-col>

                   

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        
                        <v-menu
                            ref="menuFechaRegistroIaffDireccionInterna"
                            v-model="menuFechaRegistroIaffDireccionInterna"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    dense
                                    filled
                                    color="blue-grey lighten-2"
                                    v-model="computedFechaRegistroIaffDireccionInterna"
                                    label="Fecha de registro en la dirección interna"
                                    hint="DD/MM/AAAA"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                    :rules="[
                                        required('Fecha de registro en la dirección interna'),
                                    ]"
                                    v-mask="'##/##/####'"
                                    maxlength="10"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                
                                v-model="dateFechaRegistroIaffDireccionInterna"
                                no-title
                                @input="menuFechaRegistroIaffDireccionInterna = false"
                                locale="es"
                            ></v-date-picker>
                        </v-menu>
                </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            class="required"
                            v-model="datosItem.cumplimientoInformeTiempo"
                            :items="itemsSiNo"
                            :loading="false"
                            dense
                            filled
                            label="Cumplimiento de ingreso de informe en tiempo establecido"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('Cumplimiento de ingreso de informe en tiempo establecido')
                            ]"
                            >
                        </v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            class="required"
                            v-model="datosItem.envioDocumentacionFisica"
                            :items="itemsSiNo"
                            :loading="false"
                            dense
                            filled
                            label="Envío de documentación física requerida"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('Envío de documentación física requerida')
                            ]"
                            >
                        </v-select>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            class="required"
                            v-model="datosItem.envioDocumentacionDigital"
                            :items="itemsSiNo"
                            :loading="false"
                            dense
                            filled
                            label="Envío de información en formato digital"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('Envío de información en formato digital')
                            ]"
                            >
                        </v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            class="required"
                            v-model="datosItem.fichaCumplimientoFirmas"
                            :items="itemsSiNo"
                            :loading="false"
                            dense
                            filled
                            label="Ficha de cumplimiento con firmas requeridas"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('Ficha de cumplimiento con firmas requeridas')
                            ]"
                            >
                        </v-select>
                    </v-col>


                    

                </v-row>

                <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-select
                            class="required"    
                            v-model="datosItem.fichaCumplimientoSellos"
                            :items="itemsSiNo"
                            :loading="false"
                            dense
                            filled
                            label="Ficha de cumplimiento con sellos correspondientes"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('Ficha de cumplimiento con sellos correspondientes')
                            ]"
                            >
                        </v-select>
                    </v-col>
                </v-row>

                
                <v-row>
                    <v-col md="12" sm="12" class="pt-0 pb-0">
                    <v-spacer></v-spacer>
                        <!--:disabled="!validDocForm" type="submit"-->
                        <v-btn v-if="accion === 1"
                        color="light-blue-502"
                        class="white--text mb-2 float-right"
                        type="submit"
                        :elevation="0"
                        :disabled="!validForm || !anioPeriodoSeleccionado"
                        :loading="btnRegistroLoading"
                        >
                        Guardar
                        </v-btn>

                        <v-btn
                        color="grey lighten-5"
                        elevation="0"
                        class="mb-2 float-right grey lighten-5 mr-2"
                        @click="
                            dialogRegistro = false;
                            resetForm();
                        "
                        :disabled="btnRegistroLoading"
                        >
                        {{accion === 1 ? `Cancelar` : `Cerrar`}}
                        </v-btn>
                        
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
        
        <div style="flex: 1 1 auto"></div>
        </v-card>
    </v-dialog>

    <!--end:: modal registro-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        transition="scroll-y-transition"
    ></DialogLoader>
    <!---->


    </div>
</template>
<script>
import moment from "moment";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";

import { OBTENER_IAFFS_CNS} from "@/core/services/store/proyectoscns/iaff/iaffcns.module";


import { OBTENER_SEGUIMIENTOS_CNS_EXPECIALISTA, REGISTRAR_SEGUIMIENTO_CNS, OBTENER_SEGUIMIENTO_CNS } from "@/core/services/store/proyectoscns/seguimiento/seguimiento.module";


import validations from "@/core/untils/validations.js";
export default {
    name: "RegistroSeguimientoCns",
    components:{
        DivisorColor,
        SnackAlert,
        DialogLoader
    },
    data(){
        return{
            dateFechaSuscripcion: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFechaRegistroIaffDireccionInterna: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFechaRegistroIaff: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            registrosIaff: [],
            items:[],
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            validarPeriodoPoa: false,
            validForm: false,
            btnRegistroLoading:false,
            dialogRegistro: false,
            codigoProyecto:"",
            periodos: [],
            bimestres: [],
            dialogTitle: "",
            menuFechaRegistroIaffDireccionInterna: false,
            menuFechaRegistroIaff: false,
            filtro: "",
            anioPeriodoSeleccionado:{},
            datosItem:{
                periodosId:0,
                bimestreId: 0,
                numeroBoleta: "",
                fechaRegistroIaffSegeplan: "",
                fechaRegistroIaffDireccion: "",
                cumplimientoInformeTiempo: 0,
                envioDocumentacionFisica: 0,
                envioDocumentacionDigital: 0,
                fichaCumplimientoFirmas: 0,
                fichaCumplimientoSellos: 0
            },
            tableSeguimientosLoading: false,
            seguimientos: [],
            accion: 1,
            itemsSiNo:[
                {id: 0, text: "Seleccione"},
                {id: 1, text: "Si"},
                {id: 2, text: "No"},
            ],
            ...validations
        }
    },

    methods: {
        resetForm(){
          this.accion=1;
          this.dateFechaSuscripcion=null;
          this.anioPeriodoSeleccionado = {};
      
          this.datosItem={
              periodosId:0,
              bimestreId: 0,
              numeroBoleta: "",
              fechaRegistroIaffSegeplan: "",
              fechaRegistroIaffDireccion: "",
              cumplimientoInformeTiempo: 0,
              envioDocumentacionFisica: 0,
              envioDocumentacionDigital: 0,
              fichaCumplimientoFirmas: 0,
              fichaCumplimientoSellos: 0
          };
        },

        /// Obtener los registros del iaff para extraer los bimestres y años
        async obtenerRegistrosIaff() {
            
            this.registrosIaff = [];
            this.tableLoading = true;

            await this.$store
            .dispatch(OBTENER_IAFFS_CNS, {proyectoId : this.proyectoId , estadoId: 134 })
            .then(() => {
                this.registrosIaff = this.$store.state.iaffcns.iaffsCns;
                //console.log(this.items)
                //console.log(this.items)
                this.tableLoading = false;
            })
            .catch(() => {
                this.tableLoading = false;
                //console.log(err)
            });
        },


         //Obtener las unidades ejecutoras
         async obtenerSeguimientosProyecto(proyectoId) {
            this.tableSeguimientosLoading = true;
            this.seguimientos = [];

            await this.$store
            .dispatch(OBTENER_SEGUIMIENTOS_CNS_EXPECIALISTA, {proyectoId: proyectoId })
            .then(res => {
                if(res.status===200){
                    this.seguimientos = res.data;
                }
                
                this.tableSeguimientosLoading = false;
            })
            .catch(err => {
              console.log(err)
                this.tableSeguimientosLoading = false;
                this.seguimientos = [];
            });
        },

        establecerPeriodos(){
            this.registrosIaff.forEach(item => {
                this.periodos.push({id: item.periodosId, periodo: item.periodo});
            })
        },
        
        establecerBimestres(){
            this.registrosIaff.forEach(item => {
                this.bimestres.push({id: item.bimestreId, bimsetre: item.nombreBimestre});
            })
        },

        modalNuevo(){
            this.dialogTitle = "Registrar seguimiento";
            this.dialogRegistro = true;
        },

        async registrarItem(){
            //console.log(this.anioPeriodoSeleccionado);
            this.datosItem.proyectosCnsId = this.proyectoId;
            this.datosItem.periodosId = this.anioPeriodoSeleccionado.periodosId;
            this.datosItem.bimestreId = this.anioPeriodoSeleccionado.bimestreId;
            this.datosItem.fechaRegistroIaffDireccion = this.dateFechaRegistroIaffDireccionInterna;
            this.datosItem.fechaRegistroIaffSegeplan = this.dateFechaRegistroIaff;

            this.btnRegistroLoading = true;
            
            await this.$store
            .dispatch(REGISTRAR_SEGUIMIENTO_CNS, {datos: this.datosItem})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.resetForm();
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerSeguimientosProyecto(this.proyectoId); 
                    this.dialogRegistro=false;
                    //this.obtenerDatosItem(this.nickUsuario, this.nombreUsuario, this.correoUsuario, 2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroLoading=false;
                this.dialogLoaderVisible = false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. REF::` + error);
                this.dialogLoaderVisible = false;
                this.btnRegistroLoading=false;
            });
            //console.log(this.datosItem)
        },

        //Obtener los datos especificos de una asignacion
        async obtenerDatosItem(id){
            this.dialogLoaderVisible = true;
            this.datosItem = {};

            await this.$store
            .dispatch(OBTENER_SEGUIMIENTO_CNS, id)
            .then(res => {
                if(res.status===200){
                  this.datosItem = res.data;  



                  this.anioPeriodoSeleccionado  = this.registrosIaff.find(item => 
                    (item.periodosId === this.datosItem.periodosId && item.bimestreId === this.datosItem.bimestreId)
                  );
                  
                 //console.log(this.anioPeriodoSeleccionado)

                  this.accion=2;
                  this.dialogRegistro = true;
            
                }
                
                this.dialogLoaderVisible = false;
            })
            .catch(() => {
                this.dialogLoaderVisible = false;
                this.datosItem = {};
            });
        },
        formatDatePicker (date) {
            //console.log(date)
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

    },

    created(){
        this.parameters = this.$route.params;
        this.proyectoId = parseInt(this.$CryptoJS.AES.decrypt(this.parameters.proyectoId, "KEYADMINCNS2022").toString(this.CryptoJS.enc.Utf8));
        this.codigoProyecto = this.parameters.codigoProyecto;

        this.obtenerSeguimientosProyecto(this.proyectoId);
        
        this.obtenerRegistrosIaff().then(()=>{
            this.establecerPeriodos();
            this.establecerBimestres();
        });
    },
    computed: {
        computedFechaRegistroIaffDireccionInterna:{
            get(){
                //console.log("asdf")
                return this.formatDatePicker(this.dateFechaRegistroIaffDireccionInterna)
            },
            set(newValue){
                //console.log(newValue)
                return this.formatDatePicker(moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD"))
            }
        },

        computedFechaRegistroIaff:{
            get(){
                //console.log("asdf")
                return this.formatDatePicker(this.dateFechaRegistroIaff)
            },
            set(newValue){
                //console.log(newValue)
                return this.formatDatePicker(moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD"))
            }
        },


        

        currentRoles:{
                get(){
                    return this.$store.state.auth.roles;
                }
            },
        headers(){ 
        return [
            {
            text: "Año",
            align: "start",
            sortable: false,
            value: "periodo"
            },
            {
            text: "Bimestre",
            align: "start",
            sortable: false,
            value: "nombreBimestre"
            },
            {
            text: "Número de Boleta",
            align: "start",
            sortable: true,
            value: "numeroBoleta"
            },
           /*  {
            text: "Bimestre",
            align: "start",
            sortable: true,
            value: "correo"
            },*/
            {
            text: "Estado",
            align: "start",
            sortable: true,
            value: "estado",
            filter: this.filtroEstado
            }, 
            {
            text: "Acciones",
            align: "start",
            sortable: false,
            value: ""
            }
        ]
        }
    }
}
</script>
<style lang="">
    
</style>